import { Link, PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../components/Content';
import Layout from '../components/Layout';

const NotFoundPage: FC<PageProps> = function ({ location }) {
  // const [path] = location.pathname
  //   .split('/')
  //   .filter(k => k.length > 2 && !['product', 'produit'].includes(k));
  // const keywords = path.split('-').filter(k => k.length > 2);
  // console.info('keywords', keywords);

  return (
    <Layout pathname={location.pathname} showTitle title="Not found">
      <Content>
        <h1>Page not found</h1>
        <p>
          Sorry{' '}
          <span aria-label="Pensive emoji" role="img">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </Content>
    </Layout>
  );
};

export default NotFoundPage;
